import React from "react";

export enum AuthErrorType {
	Authentication = 1,
	Authorization,
}

interface ErrorPageProps {
	errorType: AuthErrorType;
}

const ErrorPage = ({ errorType }: ErrorPageProps) => {
	let content, headerText;

	if (errorType === AuthErrorType.Authorization) {
		content = (
			<>
				Oops! It looks like you do not have permission to access this
				application. Please talk to your manager.
			</>
		);
		headerText = "Permission Error";
	} else {
		content = (
			<>
				Somehow, somewhere, our system broke down.
				<br />
				The good news is that you had nothing to do with it. The better
				news is that we have been alerted to the error and it will be
				fixed right away.
				<br />
				You can try refreshing the page.
			</>
		);
		headerText = "System Error";
	}
	return (
		<>
			<div>{headerText}</div>
			<div>{content}</div>
		</>
	);
};

export default ErrorPage;
