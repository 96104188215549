import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";
import { CognitoUser } from "amazon-cognito-identity-js";

import { getUserFullName } from "../../helpers/getCurrentUser";
import { userContext } from "../../userContext";
import {
	createSession,
	SessionUserInput,
} from "../../helpers/api/createSession";
import BacklogItemPanel from "../BacklogItemPanel/BacklogItemPanel";
import "./ManageSession.css";
import { SPCopyButton } from "../UI/SPCopyButton";
import { getSession } from "../../helpers/api/getSession";
import {
	updateSession,
	SessionUserUpdate,
} from "../../helpers/api/updateSession";

const {
	Container,
	Heading,
	SectionContent,
	InputText,
	SectionHeader,
	InputDropdown,
	Button,

	Label,
	Content,
} = require("@amaabca/react-components");
const uuid = v4();

const ManageSession = () => {
	const params = useParams();
	const existingId = params.sessionId ? params.sessionId : "";
	const [titleValue, setTitleValue] = useState("");
	const [sequencing, setSequencing] = useState("Please Select");
	const [formIsValid, setformIsValid] = useState(false);
	const [sessionCreated, setSessionCreated] = useState(false);
	const [sessionId, setsessionId] = useState("");

	const cognitoUser: CognitoUser | null = useContext(userContext);
	const currentUsersName = cognitoUser
		? getUserFullName(cognitoUser)
		: "User Not Found";

	useEffect(() => {
		document.title = "Manage Session | Scrum Poker";

		if (params.sessionId) {
			setsessionId(params.sessionId);
		} else {
			setsessionId(uuid);
		}
	}, [params.sessionId]);

	const sessionIdDisplayed: string = existingId ? existingId : sessionId;
	const placeholder = "New session must be created to add backlog items";

	const titleChangeHandler = (event: any) => {
		setTitleValue(event.target.value);
		event.preventDefault();
		event.target.value !== "" && sequencing !== "Please Select"
			? setformIsValid(true)
			: setformIsValid(false);
	};

	const dropDownHandler = (event: any) => {
		setSequencing(event.target.value);
		event.preventDefault();
		event.target.value !== "Please Select" && titleValue !== ""
			? setformIsValid(true)
			: setformIsValid(false);
	};

	const newSessionHandler = async (event: any) => {
		event.preventDefault();

		const sessionData: SessionUserInput = {
			sessionId: sessionId,
			title: titleValue,
			sequencing: sequencing,
			createdBy: currentUsersName,
		};

		const sessionCreatedId = await createSession(sessionData);
		sessionData.sessionId === sessionCreatedId && setSessionCreated(true);
		setformIsValid(false);
	};

	const sessionUpdateHandler = async (event: any) => {
		event.preventDefault();

		const sessionUpdate: SessionUserUpdate = {
			sessionId: sessionId,
			title: titleValue,
			sequencing: sequencing,
			modifiedBy: currentUsersName,
		};
		await updateSession(sessionUpdate);
		setformIsValid(false);
	};

	useEffect(() => {
		const showExisting = async () => {
			const existingSessionId = await getSession(sessionId);

			console.log("getSession returned ✨ ", existingSessionId.title);

			setTitleValue(existingSessionId.title);
			setSequencing(existingSessionId.sequencing);
			setSessionCreated(true);
			setformIsValid(false);
		};

		if (existingId && sessionId) {
			//avoids any undefined returns
			showExisting();
		}
	}, [existingId, sessionId]);

	const backlogItemPanel = sessionCreated ? (
		<BacklogItemPanel />
	) : (
		placeholder
	);
	const settingsButtonGroup = sessionCreated ? (
		<>
			<Button
				type="button"
				id="update-button"
				disabled={!formIsValid}
				onClick={sessionUpdateHandler}
				data-testid="update-button"
			>
				Update
			</Button>
			&nbsp;
			<Button type="button" id="delete-button" color="red">
				Delete
			</Button>
		</>
	) : (
		<Button id="create-button" disabled={!formIsValid} type="submit">
			Create
		</Button>
	);

	const sessionIdDisplay = sessionCreated ? (
		<SPCopyButton text={sessionIdDisplayed} type="button" />
	) : (
		<Content data-testid="sessionId">{sessionId}</Content>
	);

	return (
		<form onSubmit={newSessionHandler}>
			<SectionContent id="main">
				<Container disableGrid={true} justify="center">
					<Heading color="brandBlueLight" size="1">
						Manage Session
					</Heading>
				</Container>
				<Container
					columnStart={1}
					columnSpan={6}
					hasColumnGap={true}
					border="full"
					id="Settings"
				>
					<SectionHeader>Settings</SectionHeader>
					<Label identifier="session-id">Session</Label>
					<Container display="block">{sessionIdDisplay}</Container>

					{/* title and sequence selections below */}
					<Container columnSpan={10}>
						<Label identifier="session-title">Title</Label>
						<Container disableGrid={true}>
							<InputText
								columnEnd={10}
								hasBottomMargin={true}
								value={titleValue}
								onInput={titleChangeHandler}
								placeholder="Enter a Title"
								identifier="session-title"
								data-testid="session-title-input"
							/>
						</Container>
						<Label identifier="session-sequencing">
							Sequencing
						</Label>
						<Container disableGrid={true} hasBottomMargin={true}>
							<InputDropdown
								identifier="session-sequencing"
								value={sequencing}
								onChange={dropDownHandler}
								data-testid="session-sequencing"
							>
								<option
									data-testid="seq-select-option"
									disabled
								>
									Please Select
								</option>
								<option
									data-testid="seq-select-option"
									value="Fibonacci"
								>
									Fibonacci
								</option>
								<option
									data-testid="seq-select-option"
									value="T-shirt"
								>
									T-shirt
								</option>
								<option
									data-testid="seq-select-option"
									value="Modified Scrum"
								>
									Modified Scrum
								</option>
							</InputDropdown>
						</Container>
						<Container data-testid="button-option">
							{settingsButtonGroup}
						</Container>
					</Container>
				</Container>

				{/* Backlog Items below */}
				<Container
					columnStart={7}
					columnSpan={6}
					border="full"
					hasColumnGap={true}
					id="BacklogMain"
				>
					<Container height="100px" id="Backlog">
						<SectionHeader>Backlog Items</SectionHeader>
						<Container
							color="charcoal"
							isBold={true}
							hasBottomMargin={false}
							disableGrid={true}
						>
							{backlogItemPanel}
						</Container>
					</Container>
				</Container>
			</SectionContent>
		</form>
	);
};
export default ManageSession;
