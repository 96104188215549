import React, { useEffect, useState } from "react";
import Amplify, { Auth, Hub } from "aws-amplify";
import queryString from "query-string";
import { CognitoUser } from "amazon-cognito-identity-js";
import { awsconfig, config } from "./config";

import { UserProvider } from "./userContext";
import { getCurrentUser, redirectToLogin } from "./helpers/getCurrentUser";

import ErrorPage, { AuthErrorType } from "./components/ErrorPage";
import Header from "./components/Header/Header";
import { Outlet } from "react-router-dom";
const { Theme, ThemeProvider } = require("@amaabca/react-components");

const oauth = {
	domain: config.cognitoDomain,
	scope: config.cognitoScope,
	redirectSignIn: config.redirectSignIn,
	redirectSignOut: config.redirectSignOut,
	responseType: config.responseType,
};

Amplify.configure({
	...awsconfig,
});
Auth.configure({ oauth });

const HUB_EVENTS = {
	signIn: "signIn",
	signInFailure: "signIn_failure",
	cognitoHostedUIfailure: "cognitoHostedUI_failure",
};

enum AuthState {
	Loading,
	Authenticated,
	SignInRequired,
}

const isAgentAuthorized = (user: Partial<CognitoUser>) => {
	const payload = user.getSignInUserSession?.()?.getIdToken().payload;
	// any AMA user considered valid
	return payload?.["cognito:username"].includes("ama.ab.ca");
};

const App = () => {
	const [authState, setAuthState] = useState<AuthState>(AuthState.Loading);
	const [currentUser, setCurrentUser] = useState<Partial<CognitoUser> | null>(
		null
	);
	const [authError, setAuthError] = useState<AuthErrorType | null>(null);

	useEffect(() => {
		const handleAuth = (data: {
			payload: {
				event: string;
			};
		}) => {
			switch (data.payload.event) {
				case HUB_EVENTS.signIn:
					const path = (queryString.parse(window.location.search)
						.state || "/") as string;
					window.location.assign(path);

					break;
				case HUB_EVENTS.cognitoHostedUIfailure:
				case HUB_EVENTS.signInFailure:
					setAuthState(AuthState.SignInRequired);
					setAuthError(AuthErrorType.Authentication);
					break;
			}
		};

		Hub.listen("auth", handleAuth);
		return () => Hub.remove("auth", handleAuth);
	}, []);

	useEffect(() => {
		if (queryString.parse(window.location.search).code) {
			return;
		}

		const loadCurrentUser = async () => {
			try {
				const user = await getCurrentUser();

				if (user) {
					if (!isAgentAuthorized(user)) {
						setCurrentUser(user);
						setAuthError(AuthErrorType.Authorization);
					} else {
						setAuthState(AuthState.Authenticated);
						setCurrentUser(user);
						setAuthError(null);
					}
				} else {
					throw new Error("Cannot get current user.");
				}
			} catch (error) {
				setAuthState(AuthState.SignInRequired);
			}
		};

		loadCurrentUser();
	}, []);

	if (authError) {
		console.error("Auth error:", authError);
		return <ErrorPage errorType={authError} />;
	}

	if (authState === AuthState.Loading) {
		return <div>Loading...</div>;
	}

	if (authState === AuthState.SignInRequired) {
		redirectToLogin();
		// return null;
	}

	return (
		<ThemeProvider theme={Theme}>
			<UserProvider value={currentUser as any}>
				<Header />
				<Outlet />
			</UserProvider>
		</ThemeProvider>
	);
};

export default App;
