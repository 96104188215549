import React, { useCallback } from "react";
import styled from "styled-components";
import copy from "copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";

const { LinkButton } = require("@amaabca/react-components");

const CopyButton = styled(LinkButton)`
	padding-left: 10px;
`;

export const SPCopyButton = (props: { text: string; type?: string }) => {
	const preventFocusButton = useCallback(
		(e: Event) => e.preventDefault(),
		[]
	);
	const copyToClipboard = useCallback(
		() => props.text && copy(props.text),
		[props.text]
	);

	return (
		<>
			{props.text}
			{props.text && (
				<CopyButton
					type={props.type}
					onMouseDown={preventFocusButton}
					onClick={copyToClipboard}
				>
					<FontAwesomeIcon icon={faCopy} />
					{" Copy"}
				</CopyButton>
			)}
		</>
	);
};
