import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { config } from "../config";

export const getUserFirstName = (currentUser: CognitoUser) => {
	const payload = currentUser

		?.getSignInUserSession?.()

		?.getIdToken()?.payload;

	if (!payload) {
		console.log("🎈", "unable to getUserFirstName check Auth");
		return "";
	}
	// console.log("🎈", payload);
	return `${payload["given_name"]}`;
};

export const getUserFullName = (currentUser: CognitoUser) => {
	const payload = currentUser

		?.getSignInUserSession?.()

		?.getIdToken()?.payload;

	if (!payload) {
		console.log("🎈", "unable to getUserFullName check Auth");
		return "";
	}
	// console.log("🎈", payload);
	return `${payload["given_name"]} ${payload["family_name"]}`;
};

export const redirectToLogin = () => {
	console.log("Redirecting to login");
	const encodedPath = encodeURIComponent(
		window.location.pathname + window.location.search
	);
	window.location.assign(`${config.signInUrl}&state=${encodedPath}`);
};

export const getCurrentUser = async (
	callbackOnError: () => void = redirectToLogin
): Promise<CognitoUser> => {
	try {
		const user = await Auth.currentAuthenticatedUser();
		let userSession = user?.getSignInUserSession();

		if (!userSession) {
			throw Error("There is no user session. Running callback...");
		} else {
			return user;
		}
	} catch (error) {
		console.log(error);
		callbackOnError?.();
		throw error;
	}
};
