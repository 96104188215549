const site = "https://staging-scrum.ama.ab.ca";
// const site = "http://localhost:3000";
const clientId = "4hoattt8rdhientbm8rmqidjht";

export const config = {
	signInUrl: `https://stage-ama-internal.auth.us-west-2.amazoncognito.com/oauth2/authorize?redirect_uri=${site}&response_type=code&client_id=${clientId}&identity_provider=azure`,
	cognitoDomain: "stage-ama-internal.auth.us-west-2.amazoncognito.com",
	cognitoScope: [
		"phone",
		"email",
		"profile",
		"openid",
		"aws.cognito.signin.user.admin",
	],
	redirectSignIn: site,
	redirectSignOut: site,
	responseType: "code",
};
export const awsconfig = {
	aws_appsync_region: "us-west-2",
	aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
	aws_project_region: "us-west-2",
	aws_user_pools_id: "us-west-2_rh1c15nOb",
	aws_user_pools_web_client_id: clientId,
	mandatorySignIn: true,
	api_url_sessions:
		"https://f3klqp4dci.execute-api.us-west-2.amazonaws.com/prod/sessions/",
};
