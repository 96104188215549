import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPageView from "./components/LandingPage/LandingPageView";
import ManageSessionView from "./components/ManageSession/ManageSessionView";
import EstimationView from "./components/Estimation/EstimationView";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<App />}>
					<Route index element={<LandingPageView />} />
					<Route
						path="/managesession"
						element={<ManageSessionView />}
					/>
					<Route
						path="/managesession/:sessionId"
						element={<ManageSessionView />}
					/>
					<Route path="estimation" element={<EstimationView />} />
				</Route>
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);
