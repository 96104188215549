import { awsconfig } from "./../../config";
import axios, { AxiosError, AxiosRequestConfig } from "axios";

export interface SessionUserUpdate {
	sessionId: string;
	title: string;
	sequencing: string;
	modifiedBy: string;
}

interface UpdateSessionDbData extends SessionUserUpdate {
	_objectType: string;
	isActive: boolean;
}

const api_url = awsconfig.api_url_sessions;

export const updateSession = async (
	sessionUpdateUserInfo: SessionUserUpdate
) => {
	try {
		const dbUpdateSessionInfo: UpdateSessionDbData = {
			...sessionUpdateUserInfo,
			_objectType: "Session",
			isActive: false,
		};

		console.log(
			"updating customer AxiosRequest...",
			dbUpdateSessionInfo,
			api_url
		);

		const config: AxiosRequestConfig = {
			url: api_url,
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
			},
			data: { ...dbUpdateSessionInfo },
		};

		const response = await axios.request(config);

		console.log("response received", response);

		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			const axiosError = error as AxiosError;
			console.log("Axios error", axiosError);
		} else {
			console.log(
				`Unexpected error in updateSession with sessionId: ${JSON.stringify(
					sessionUpdateUserInfo
				)}`,
				error
			);
		}
		return { title: undefined };
	}
};
