import React from "react";
import AuthenticatedApp from "../../AuthenicatedApp";

const { SectionHeader } = require("@amaabca/react-components");

const Header = () => {
	return (
		<div>
			<SectionHeader
				rightSideContent={<AuthenticatedApp />}
				rightSideColumnStart={10}
			>
				Scrum Poker
			</SectionHeader>
		</div>
	);
};

export default Header;
