import React, { useEffect } from "react";
const { Heading } = require("@amaabca/react-components");

const EstimationView = () => {
	useEffect(() => {
		document.title = "Estimation | Scrum Poker";
	});
	return (
		<Heading color="brandBlueLight" size="2">
			Estimate Work Item
		</Heading>
	);
};

export default EstimationView;
