import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const {
	Heading,
	Container,
	Button,
	InputText,
	SectionContent,
	Content,
} = require("@amaabca/react-components");

const LandingPageView = () => {
	useEffect(() => {
		document.title = "Home | Scrum Poker";
	});

	const [sessionValue, setSessionValue] = useState("");
	const [fieldIsValid, setFieldIsValid] = useState(true);

	const sessionChangeHandler = (event: any) => {
		setSessionValue(event.target.value);
		event.preventDefault();
	};

	const submitHandler = (event: any) => {
		event.preventDefault();
		const sessionIdValue = sessionValue;
		if (sessionIdValue === "") {
			setFieldIsValid(false);
			return;
		}
		setFieldIsValid(true);

		console.log(sessionIdValue);
	};

	return (
		<form onSubmit={submitHandler}>
			<SectionContent id="landing-main-container">
				<Container disableGrid={true} justify="center" padding="top">
					<Heading color="brandBlueLight" size="1">
						AMA Scrum Poker
					</Heading>
				</Container>

				<Container disableGrid={true} justify="center" padding="top">
					<Link to="/managesession">
						<Button>Create Session</Button>
					</Link>
				</Container>
				<Container disableGrid={true} justify="center">
					<InputText
						placeholder="Session ID"
						value={sessionValue}
						onInput={sessionChangeHandler}
					/>
					{!fieldIsValid && (
						<Content color="brandRed" hasBottomMargin={false}>
							Please Enter Valid Session ID!
						</Content>
					)}
				</Container>

				<Container disableGrid={true} justify="center" padding="top">
					<Button>Join Session</Button>
				</Container>

				<Container disableGrid={true} justify="center" padding="top">
					<Button disabled={true}>My Sessions</Button>
				</Container>
			</SectionContent>
		</form>
	);
};

export default LandingPageView;
