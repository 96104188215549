import React from "react";

const {
	Container,
	InputText,
	Button,
	Label,
} = require("@amaabca/react-components");

const BacklogItemPanel = () => {
	return (
		<Container columnStart={7} columnSpan={6}>
			<Label identifier="add-backlog-item">Add Backlog Items</Label>
			<InputText
				data-testid="backlog-item-panel"
				columnEnd={10}
				hasBottomMargin={false}
				identifier="add-backlog-item"
			/>
			<Button type="button">Add</Button>
		</Container>
	);
};

export default BacklogItemPanel;
