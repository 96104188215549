import { awsconfig } from "./../../config";
import axios, { AxiosError, AxiosRequestConfig } from "axios";

export interface SessionUserInput {
	sessionId: string;
	title: string;
	sequencing: string;
	createdBy: string;
}

interface SessionDbData extends SessionUserInput {
	_objectType: string;
	isActive: boolean;
}

const api_url = awsconfig.api_url_sessions;

export const createSession = async (sessionUserInfo: SessionUserInput) => {
	try {
		const dbSessionInfo: SessionDbData = {
			...sessionUserInfo,
			_objectType: "Session",
			isActive: false,
		};

		const config: AxiosRequestConfig = {
			url: api_url,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			data: { ...dbSessionInfo },
		};

		const response = await axios.request(config);

		return response.data as String;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			const axiosError = error as AxiosError;
			console.log("Axios error", axiosError);
		} else {
			console.log(
				`Unexpected error in createSession with sessionId: ${JSON.stringify(
					sessionUserInfo
				)}`,
				error
			);
		}

		return { sessionId: undefined };
	}
};
