import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { awsconfig } from "../../config";
// import { v4 as uuidv4 } from "uuid";

const api_url = awsconfig.api_url_sessions;

export const getSession = async (sessionId: string) => {
	const reqApiUrl = `${api_url}?sessionId=${sessionId}`;

	try {
		const config: AxiosRequestConfig = {
			url: reqApiUrl,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
			data: { sessionId },
		};
		const response = await axios.request(config);
		console.log(response.data);
		return response.data[0];
	} catch (error: unknown) {
		if (axios.isAxiosError(error)) {
			const axiosError = error as AxiosError;
			console.log("Error Response:", axiosError.response);
		} else {
			console.log("Error:", error);
		}
		return { getExistingSession: undefined };
	}
};
