import React, { useContext } from "react";
import { userContext } from "./userContext";
import { getUserFirstName } from "./helpers/getCurrentUser";
import { CognitoUser } from "amazon-cognito-identity-js";

const { Content } = require("@amaabca/react-components");

const AuthenticatedApp = () => {
	const cognitoUser: CognitoUser | null = useContext(userContext);
	const user = cognitoUser
		? getUserFirstName(cognitoUser)
		: "Please Sign-in to SSO";

	return (
		<Content isItalic isRightAligned color="white" hasBottomMargin={true}>
			Welcome, {user}
		</Content>
	);
};

export default AuthenticatedApp;
